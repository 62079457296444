header,
footer {
    margin: 50px 0;
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    font-size: 12pt;
    font-weight: 700;
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.brand {
    font-size: 16pt;
    align-self: center;
}
